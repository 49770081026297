export const getProductDataQuery = `
  query GetProductDetails($type: ProductType!, $serialNumber: String!, $originalBoilerSapNumber: String) {
    getProductDetails(type: $type, serialNumber: $serialNumber, originalBoilerSapNumber: $originalBoilerSapNumber) {
      serialNumber
      sapNumber
      modelName
      friendlyName
      version
      applianceModelId
      applianceId
      imageUrl
      currentWarrantyLength
      warrantyDurationUnit
      hasFilterCheck
      salesforceId
      isRegistered
      activeRecall
      linkedProductGrouping {
        groupName
        products {
         productCode
         productType
       }
      }
    }
  }
`;

export const getProductExtendedWarrantyDetails = `
 query GetProductExtendedWarrantyDetails($productCode: String!, $gasType: String!) {
    getProductExtendedWarrantyDetails(productCode: $productCode, gasType: $gasType) {
      months
      coLowRange {
        min
        max
      }
      coHighRange {
        min
        max
      }
      co2LowRange {
        min
        max
      }
      co2HighRange {
        min
        max
      }
      o2LowRange {
        min
        max
      }
      o2HighRange {
        min
        max
      }
    }
  }
`;

export const getGasSafeEngineersQuery = `
  query GetGasSafeEngineers($businessRegistrationNumber: String!, $installationDate: AWSDate!) {
    getGasSafeEngineers(businessRegistrationNumber: $businessRegistrationNumber, installationDate: $installationDate) {
      hidePhoto
      engineerId
      engineerCardNo
      nino
      firstName
      lastName
      cardIssueDate
      cardExpiryDate
      workCategories {
        workCategoryID
        workCategoryName
        reportable
        hasBoilers
        collectApplianceData
        collectLocationData
        allowOtherVersion
        expiryDate
      }
      cardIds {
        cardId
        status
      }
      businessId
      businessRegNo
    }
  }
`;

export const getMyInstallerAccountQuery = `
  query GetMyInstallerAccount {
    getMyInstallerAccount {
      isApproved
      waitingForApproval
      firstName
      lastName
      username
      email
      cognitoId
      savedRegistrationCount
      lastSeenMaxAIEligible
      features
      account {
        id
        gasSafeNumber
        cpsName
        band
        name
        houseNumber
        street1
        city
        country
        countryCode
        postCode
        region
        businessEmail
        phone
        website
        furtherInformation
        contacts {
          email
          firstName
          id
          lastName
          phone
          mobilePhone
          installerConnectEmail
          dateLogi80TermsAccepted
        }
        maxAiStatus
        platinumInstaller
        termsConditionsAccepted
        doNotEmail
        maxAiRenewalDate
        maxAiEligibilityPoints
        level
        benchmarkEngineerId
        preferredBoilerBrand
      }
    }
    getTalonOneProfile {
      totalBoilersRegistered
      thisYearRegistrations 
      lastYearRegistrations 
      registrationsByType {
        productType
        total
        currentYear
        lastYear
      }
      bandDetails {
        currentBand
        nextBand
        registrationsAllTime
        registrationsThisYear
        registrationsUntilNextBand
        percentageToNextBand
      }
      points
    }
  }
`;

export const getMyCampaignByIdQuery = `
  query GetMyCampaignById($campaignId: String!) {
    getMyCampaignById(campaignId: $campaignId){
      name
      startDate
      endDate
      target
      current
      percent
      onTrack
      interval
      productCeiling
      achieved
    }
  }
`;

export const getMyInstallerUpdateRequestsQuery = `
  query GetMyInstallerUpdateRequests {
    getMyInstallerUpdateRequests {
      companyName
      companyHouseNumber
      companyStreet
      companyCity
      companyPostcode
      companyCountry
      companyRegion
      companyEmail
      companyTelephone
      companyWebsite
      companyFurtherInfo
      gasSafeRegistrationNumber
      installerFirstName
      installerLastName
      installerTelephone
      installerMobile
    }
  }
`;

export const getBasketQuery = `
  query GetBasket {
    getBasket {
      items {
        itemId
        quantity
        minimumQty
        maximumQty
        title
        slug
        options { group variant }
        pointsRequired
        pointsRequiredRoi
        pointsRequiredCa
        pointsRequiredUs
        pointsRequiredMaxAi
        image { url altText }
        userInputLabel
        userInput
      }
    }
  }
`;

export const getOrderQuery = `
  query GetOrder($orderId: String!) {
  getOrder(orderId: $orderId) {
    address {
      city
      country
      county
      postCode
      street
    }
    date
    id
    orderItems {
      productCode
      id
      points
      quantity
      title
      description
      image {
        altText
        url
      }
      hasDownload
      userInputLabel
      userInput
    }
    orderNumber
    status
    totalPoints
  }
}
`;

export const getOrdersQuery = `
  query GetOrders($page: Int!, $pageSize: Int!, $searchFilter:String) {
  getOrderHistory(page: $page, pageSize: $pageSize, searchFilter: $searchFilter) {
    orders {
      date
      id
      orderNumber
      status
      totalPoints
      orderItems {
        id
        image {
          altText
          url
        }
        points
        quantity
        title
      }
      address {
        city
        country
        county
        postCode
        street
      }
    }
    totalOrders
  }
}
`;

export const getFailedRegistrationsQuery = `
  query GetFailedRegistrations {
    getFailedRegistrations {
      id
      serialNumber
      friendlyName
    }
  }
`;

export const getRegisteredAssetsQuery = `
  query GetRegisteredAssets($page: Int!, $pageSize: Int!, $assetType: AssetType, $searchFilter: String) {
    getAssetRegistrationHistory(page: $page, pageSize: $pageSize, assetType: $assetType, searchFilter: $searchFilter) {
      items {
        id
        installationDate
        registrationDate
        serialNumber
        houseNumber
        street
        street_2
        street_3
        street_4
        region
        city
        postcode
        country
        productName
        assetType
        warrantyStartDate
        warrantyEndDate
        benchmarkInstallationId
        customerName
      }
      totalItems
      band
  }
}
`;

export const getSafetyRecallAssetsQuery = `
  query GetSafetyRecallAssets($page: Int!, $pageSize: Int!, $searchFilter: String, $status: String) {
    getAssetRecallWorkOrders(page: $page, pageSize: $pageSize, searchFilter: $searchFilter, status: $status) {
      items {
        id
        jobReportId
        status
        invoiceStatus
        installationDate
        serialNumber
        houseNumber
        street
        street_2
        street_3
        street_4
        region
        city
        postcode
        country
        productName
        customerName
        customerPhoneNumber
        targetFixDate
      }
      totalItems
  }
}
`;

export const getJobReportDetailsQuery = `
  query GetJobReportDetails($workOrderId: String!) {
    getJobReportDetails(workOrderId: $workOrderId) {
      workOrder {
        id
        status
        invoiceStatus
      }
      jobReport {
        id
        jobDate
        staticPressure
        gasRate
        dynamicGasPressure
        cOPpmHigh
        cOPpmLow
        cO2PercentageHigh
        cO2PercentageLow
        o2PercentageHigh
        o2PercentageLow
        warningNoticeIssued
        kitFitted
        notes
        images {
          filename
          dataUrl
        }
      }
      customerDetails {
        customerName
        customerPhone
        address {
          houseNumber
          street
          street_2
          street_3
          street_4
          region
          city
          postcode
          country
        }
      }
      assetInfo {
        id
        serialNumber
        modelName
        imageUrl
      }
    }
  }
`;

export const getRegisteredAssetDetailsQuery = `
  query GetRegisteredAssetDetails($assetId: String!) {
    getAssetRegistrationHistoryDetails(assetId: $assetId) {
      assetDetails {
        id
        installationDate
        registrationDate
        serialNumber
        houseNumber
        street
        street_2
        street_3
        street_4
        region
        city
        postcode
        country
        productName
        assetType
        warrantyStartDate
        warrantyEndDate
        imageUrl
        customerName
        customerEmail
        customerPhone
        gasSafeCertificateNumber
        state
        isWarrantyCertificateEligible
        benchmarkInstallationId
        originalBoilerSerialNumber
        gasType
    }
  }
}
`;

export const getMaxAiEligibilityQuery = `
  query GetMaxAiEligibility {
    getMaxAiEligibility {
      isEligible
      eligibilityCriteria {
        isMet
        message
      }
    }
  }
`;

export const getMaxAiSubmittedApplicationDetailsQuery = `
query GetMaxAiSubmittedApplicationDetails {
  getMaxAiSubmittedApplicationDetails {
    id
    applicationDate
    assessmentDeadline
    maxAiStatus
    trainingPlatformUrl
    trainingPlatformEnabled
  }
}`;

export const getBoilerServiceReminders = `
  query GetBoilerServiceReminders($month: Int, $year: String) {
    getBoilerServiceReminders(month: $month, year: $year) {
      items {
        id
        installationDate
        productName
      }
  }
}
`;

export const verifyInstallerWorkCategories = `
  query VerifyInstallerWorkCategories($engineerId: String!, $installationDate: AWSDate!, $isLpgRegistration: Boolean) {
    verifyInstallerWorkCategories(engineerId: $engineerId, installationDate: $installationDate, isLpgRegistration: $isLpgRegistration)
  }
`;

export const getProcessingAssetRegistrations = `
  query GetProcessingAssetRegistrations {
    getProcessingAssetRegistrations {
      primarySerialNumber
      points
      state
    }
  }
`;

export const listInProgressAssetRegistrations = `
  query ListInProgressAssetRegistrations {
    listInProgressAssetRegistrations {
      id
      serialNumber
      friendlyName
      currentStep {
        index
        id
      }
      formData {
        installerName
        submissionAssetType
        gasSafe
        assetType
        gasType
        assetInfo {
          applianceId
          applianceModelId
          currentWarrantyLength
          hasFilterCheck
          imageUrl
          isRegistered
          modelName
          friendlyName
          salesforceId
          sapNumber
          serialNumber
          version
          warrantyDurationUnit
        }
        controlAssetInfo {
          applianceId
          applianceModelId
          currentWarrantyLength
          hasFilterCheck
          imageUrl
          isRegistered
          modelName
          friendlyName
          salesforceId
          sapNumber
          serialNumber
          version
          warrantyDurationUnit
        }
        cylinderAssetInfo {
          applianceId
          applianceModelId
          currentWarrantyLength
          hasFilterCheck
          imageUrl
          isRegistered
          modelName
          friendlyName
          salesforceId
          sapNumber
          serialNumber
          version
          warrantyDurationUnit
        }
        filterAssetInfo {
          applianceId
          applianceModelId
          currentWarrantyLength
          hasFilterCheck
          imageUrl
          isRegistered
          modelName
          friendlyName
          salesforceId
          sapNumber
          serialNumber
          version
          warrantyDurationUnit
        }
        customerDetails {
          customerConsent
          customerDetailsKnown
          controllerToControllerAgreement
          email
          firstName
          lastName
          newBuiltHome
          telephone
          title
        }
        engineerDetails {
          businessRegistrationNumber
          name
          engineerId
        }
        installationDetails {
          address
          boilerLocation
          city
          installationDate
          houseNumber
          laCode
          postcode
          propertyType
          street
          region
          latitude
          longitude
        }
        gasSafeEmail
        gasSafeAlternateAddress {
          address
          city
          firstName
          houseNumber
          lastName
          postcode
          street
          title
        }
        lpgInfo {
          applianceId
          applianceModelId
          currentWarrantyLength
          hasFilterCheck
          imageUrl
          isRegistered
          modelName
          friendlyName
          salesforceId
          sapNumber
          serialNumber
          version
          warrantyDurationUnit
        }
        merchantDetails {
          merchant
          merchantLocation
        }
        extendedWarranty {
          apply
          coHigh
          coLow
          co2High
          co2Low
          o2High
          o2Low
          images {
            filename
            dataUrl
          }
        }
      }
      currentProgress
      isEditing
      updatedAt
    }
  }
`;

export const getBenchmarkInstallForm = `
  query GetBenchmarkInstallForm {
    getBenchmarkInstallForm {
      formStructure
    }
  }
`;

export const getBenchmarkPresignedUrlQuery = `
  query GetBenchmarkPresignedUrl {
    getBenchmarkPresignedUrl {
      presignedUrl
      filePath
    }
  }
`;

export const getProductModelDataQuery = `
  query GetBenchmarkProductModelData($serialNumber: String!, $assetType: String!) {
    getBenchmarkProductModelData(serialNumber: $serialNumber, assetType: $assetType){
      combinationBoiler
      imageRequired
    }
  }
`;

export const getBenchmarkInstallationRecord = `
  query GetBenchmarkInstallationRecord($serialNumber: String!) {
    getBenchmarkInstallationRecord(serialNumber: $serialNumber) 
  }
`;

export const hasBankDetailsQuery = `
  query HasBankDetails {
    hasBankDetails
  }
`;

export const getInvoicesQuery = `
  query GetInvoices($page: Int!, $pageSize: Int!) {
    getInvoices(page: $page, pageSize: $pageSize) {
      items {
        createdDate
        id
        name
      }
      totalItems
    }
  }
`;

export const getPremierClubSummaryQuery = `
  query GetPremierClubSummary {
    getPremierClubSummary {
      startDate
      endDate
      isEnrolled
      enrolledOn
      activeCampaign
      campaignName
      totals {
        pointsTarget
        pointsTotal
        baseBoilersCount
        premiumBoilersCount
        controlsCount
        cylindersCount
        heatPumpCount
      }
      attending
      attendanceConfirmedOn
    }
  }
`;

export const getWarrantyOptionsQuery = `
  query GetWarrantyOptions(
    $countryCode: String!
    $boilers: [String]
    $controls: [String]
    $cylinders: [String]
    $filters: [String]
    $heatPumps: [String]
    $heatPumpWaterHeaters: [String]
  ) {
    getWarrantyOptions(
      countryCode: $countryCode
      boilers: $boilers,
      controls: $controls,
      cylinders: $cylinders,
      filters: $filters,
      heatPumps: $heatPumps,
      heatPumpWaterHeaters: $heatPumpWaterHeaters,
    ) {
      title
      information {
        title
        json
      }
      options {
        title
        couponCode
        imageUrl
        description {
          title
          json
        }
      }
    }
  }
`;
