import React, { useCallback, useState } from "react";
import { API } from "aws-amplify";

export default function useGraphql(query) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState([]);

  const executeQuery = useCallback(
    async (variables) => {
      setLoading(true);

      let result;

      try {
        result = await API.graphql({
          query,
          variables,
        });

        setData(result.data);
      } catch (errorResponse) {
        console.warn(errorResponse);

        result = errorResponse;

        const e = formatErrors(errorResponse);

        setErrors(e);
      }

      setLoading(false);

      return result;
    },
    [query]
  );

  return {
    executeQuery,
    loading,
    data,
    errors,
    clearErrors: () => setErrors([]),
  };
}

function formatErrors(errorResponse) {
  const defaultError = {
    message: (
      <span>
        Internal server error, please try again. Please{" "}
        <a href="/app/contact" target="_blank" className="underline">
          contact our Connect support team
        </a>
        , if the issue persists.
      </span>
    ),
  };

  if (!Array.isArray(errorResponse.errors)) {
    return [defaultError];
  }

  return errorResponse.errors.map((err) => {
    // If there is no error type then the error wasn't intentionally raised
    // by us so we should overwrite the message with something less AppSyncy
    if (!err.errorType) {
      return {
        ...err,
        ...defaultError,
      };
    }

    return err;
  });
}
