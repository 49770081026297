import React, { createContext, useEffect, useMemo, useState } from "react";
import { head } from "lodash";
import { graphql, useStaticQuery } from "gatsby";
import { useAuth, useLocale } from "../hooks";
import { filterAnnouncementsForUser } from "../services/announcements";

export const AnnouncementsContext = createContext({
  announcements: [],
});

export const AnnouncementsProvider = ({ children }) => {
  const { locale } = useLocale();
  const { user, isMaxAi, loadingSession } = useAuth();
  const [announcements, setAnnoucements] = useState([]);

  const result = useStaticQuery(graphql`
    query SiteAnnouncements {
      allContentfulSiteAnnouncements(sort: { updatedAt: DESC }) {
        nodes {
          id
          bannerMessage
          fullAnnouncement
          shortBannerMessage
          showTo
          updatedAt
          video
          node_locale
        }
      }
    }
  `);

  const firstAnnouncement = useMemo(() => head(announcements), [announcements]);

  const localisedAnnouncements = useMemo(
    () =>
      result.allContentfulSiteAnnouncements.nodes.filter(
        (node) => node.node_locale === locale,
      ),
    [result.allContentfulSiteAnnouncements.nodes, locale],
  );

  useEffect(() => {
    if (!loadingSession) {
      const filteredAnnouncements = filterAnnouncementsForUser(
        user,
        isMaxAi,
        localisedAnnouncements,
      );
      setAnnoucements(filteredAnnouncements);
    }
  }, [loadingSession, user, localisedAnnouncements]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AnnouncementsContext.Provider
      value={{
        announcements,
        firstAnnouncement,
        hasAnnouncements: announcements.length > 0,
      }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
};
